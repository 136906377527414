@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Open+Sans:wght@400;700&display=swap');
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body, html {
  /* height: 100%; */
  margin: 0;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  /* height: 100vh; */
  /* overflow: hidden; */
  block-size: 100%;
}

:root {
  --app-height: 100%;
 }

#root {
  block-size: 100%;
  /* height: 100%; */
}

/* Turn off parallax scrolling for tablets and phones */
/* @media only screen and (max-device-width: 1024px) {
  .bgimg-1, .bgimg-2, .bgimg-3 {
    background-attachment: scroll;
  }
} */
